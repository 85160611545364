<script setup lang="ts">
const links = [{
  label: 'Agents',
  icon: 'i-heroicons-user-plus',
  to: '/agents'
}, {
  label: 'Leads',
  icon: 'i-heroicons-document-plus',
  to: '/leads'
}, {
  label: 'Settings',
  icon: 'i-heroicons-cog-8-tooth',
  to: '/settings'
}]
const { signIn, signOut, status } = useAuth()
const loggedIn = computed(() => status.value === 'authenticated')

</script>

<template>
  <UHeader :links="links" :ui="{ wrapper: 'lg:border-b-0 lg:top-4 lg:mb-8 backdrop-blur-none bg-transparent lg:mx-4', container: 'lg:rounded-lg bg-white dark:bg-gray-900 lg:shadow' }">
    <template #logo>
      Agentsmith <!-- <UBadge label="Landing" class="mb-0.5" /> -->
    </template>

    <template v-if="loggedIn" #right>
      <UButton label="Sign out" @click="signOut({ callbackUrl: '/' })" color="gray" variant="ghost" class="hidden lg:flex" />
    </template>
    <template v-else #right>
      <UButton label="Sign in" @click="signIn(provider='auth0', options={ callbackUrl: '/agents' })" color="gray" variant="ghost" class="hidden lg:flex" />
      <UButton label="Get started" color="black" class="hidden lg:flex" />
    </template>

    <template #panel>
      <UAsideLinks :links="links" />

      <UDivider class="my-6" />

      <UContainer  v-if="loggedIn">
        <UButton label="Sign out" @click="signOut({ callbackUrl: '/' })" size="xl" color="gray" block class="mb-3" />
      </UContainer>
      <UContainer v-else>
        <UButton label="Sign in"  @click="signIn(provider='auth0', options={ callbackUrl: '/agents' })" size="xl" color="gray" block class="mb-3" />
        <UButton label="Get started" @click="signIn(provider='auth0', options={ callbackUrl: '/agents' })" size="xl" block />
      </UContainer>
      
      </template>
  </UHeader>
</template>