<script setup lang="ts">

</script>
<template>
	<div>
		<DashboardHeader />
		<UContainer>
			<UPage>
				<slot />
			</UPage>
		</UContainer>
	</div>
</template>